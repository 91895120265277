import React, { FormEvent, useEffect, useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Layout } from "../../components/layout";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { SEO } from "../../components/seo";

export const pageQuery = graphql`
  query NewProjectSuccessPage {
    site {
      siteMetadata {
        pages {
            newProject {
            title
            description
          }
        }
      }
    }
  }
`;

interface NewProjectSuccessPageProps extends PageProps {
  data: Queries.NewProjectSuccessPageQuery;
}

export default function NewProjectSuccessPage(props: NewProjectSuccessPageProps) {
  const { data } = props;
  const { title, description } = data.site?.siteMetadata?.pages?.newProject as Queries.SiteSiteMetadataPageSuccessNewProject;

  const seo = {
    title: title as string,
    description: description as string,
  };

  return (
    <Layout>
      <SEO {...seo} />
      <PageHeaderSection
        title="¡Gracias por tu mensaje!"
        subtitle={
          <>
            Gracias por tu interés en nuestros servicios. ¡Pronto te contactaremos!
          </>
        }
      />
    </Layout>
  );
}
